export const allLanguages = [
  { name: "English", langCode: "en-US", script: "Latn", native: "English", flag: "flag-en" },
  { name: "Dansk", langCode: "da-DK", script: "Latn", native: "Dansk", flag: "flag-da" },
  { name: "Portuguese", langCode: "pt-PT", script: "Latn", native: "Português", flag: "flag-pt" },
  { name: "Dutch", langCode: "nl-NL", script: "Latn", native: "Nederlands", flag: "flag-nl" },
  { name: "Italian", langCode: "it-IT", script: "Latn", native: "Italiano", flag: "flag-it" },
  { name: "Russian", langCode: "ru-RU", script: "Cyrl", native: "Русский", flag: "flag-ru" },
  { name: "Lithuanian", langCode: "lt-LT", script: "Latn", native: "Lietuvių", flag: "flag-lt" },
  { name: "Polish", langCode: "pl-PL", script: "Latn", native: "Polska", flag: "flag-pl" },
  { name: "Icelandic", langCode: "is-IS", script: "Latn", native: "Íslenskt", flag: "flag-is" },
  { name: "Spanish", langCode: "es-ES", script: "Latn", native: "Español", flag: "flag-es" },
  { name: "German", langCode: "de-DE", script: "Latn", native: "Deutsch", flag: "flag-de" },
  { name: "Swedish", langCode: "fi-FI", script: "Latn", native: "Suomalainen", flag: "flag-fi" },
  { name: "French", langCode: "fr-FR", script: "Latn", native: "Français", flag: "flag-fr" },
  { name: "Turkish", langCode: "tr-TR", script: "Latn", native: "Türkçe", flag: "flag-tr" },
  { name: "Serbian", langCode: "sr-RS", script: "Cyrl", native: "Српски", flag: "flag-sr" },
  { name: "Norwegian", langCode: "nb-NO", script: "Latn", native: "Norsk", flag: "flag-nb" },
  { name: "Swedish", langCode: "sv-SE", script: "Latn", native: "Svenska", flag: "flag-sv" },
];
