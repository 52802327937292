<app-header></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center">
    <div class="col-lg-8">
      <div class="white-box margin-top-inverse-85 form edit-profile">
        <figure class="image">
          <img src="{{ data?.image }}" alt="User Image" class="img-responsive">
        </figure>
        <div class="change-image">
          <input type="hidden" [(ngModel)]="filedata.oldname">
          <input type="file" id="file" (change)="fileChange($event)" accept=".png, .jpeg, .jpg, .gif" class="hidden">
          <label for="file" class="text-semibold green-color">
            <i class="fa fa-upload" aria-hidden="true"></i>
            {{ 'Change Image' | translate }}<br>
            <em class="font-12 black-color dim-text">({{ 'Image must be square - jpg or png' | translate }})</em>
          </label><br>
          <a (click)="removeImage()" class="text-semibold green-color">
            <i class="fa fa-trash" aria-hidden="true"></i>
            {{ 'Remove Image' | translate }}
          </a>
        </div>
        <mat-tab-group [@.disabled]="true" [selectedIndex]="tab">
          <mat-tab label="{{ 'Edit Profile' | translate }}">
            <flash-messages></flash-messages>
            <div>
              <mat-form-field>
                <input matInput type="text" id="name" placeholder="{{ 'Name' | translate }}" [(ngModel)]="profile.name"
                      [formControl]="nameFormControl">
                <mat-error *ngIf="nameFormControl.hasError('required')">
                  {{ 'Name is required' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput type="email" id="email" placeholder="{{ 'Email' | translate }}" [(ngModel)]="profile.email"
                      [formControl]="emailFormControl" [readonly]="this.openIdSession">
                <mat-error *ngIf="emailFormControl.hasError('pattern')">
                  {{ 'Please enter a valid email address' | translate }}
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                  {{ 'Email is required' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <textarea id="description" matInput placeholder="{{ 'Description' | translate }}"
                          [(ngModel)]="profile.description" rows="6"></textarea>
              </mat-form-field>
              <div *ngIf="data">
                <!--
                <label class="margin-top-10 margin-bottom-10 full-width">Social</label>
                <div class="pull-left margin-right-20" *ngIf="data?.social !== 'FB'">
                  <button mat-raised-button type="button" class="social-link facebook" (click)="linkSocial('facebook')" *ngIf="!fbLinkStatus else unlinkFBBtn">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                    {{ 'Link Your /social/ Account' | translate:{social: 'Facebook'} }}
                  </button>
                  <ng-template #unlinkFBBtn>
                    <button mat-raised-button type="button" class="social-link facebook" (click)="unlinkSocial('facebook')">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                        {{ 'Unlink Your /social/ Account' | translate:{social: 'Facebook'} }}
                      </button>
                  </ng-template>
                </div>
                <div class="pull-left" *ngIf="data?.social !== 'LI'">
                  <button mat-raised-button type="button" class="social-link linkedin" (click)="linkSocial('linkedin')" *ngIf="!liLinkStatus else unlinkLIBtn">
                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                    {{ 'Link Your /social/ Account' | translate:{social: 'LinkedIn'} }}
                  </button>
                  <ng-template #unlinkLIBtn>
                    <button mat-raised-button type="button" class="social-link linkedin" (click)="unlinkSocial('linkedin')">
                      <i class="fa fa-linkedin" aria-hidden="true"></i>
                      {{ 'Unlink Your /social/ Account' | translate:{social: 'LinkedIn'} }}
                    </button>
                  </ng-tempProfile Linklate>
                </div>
                <div class="pull-left" *ngIf="data?.social !== 'GP'">
                    <button mat-raised-button type="button" class="social-link google-plus" (click)="linkSocial('google')" *ngIf="!gpLinkStatus else unlinkGPBtn">
                      <i class="fa fa-google" aria-hidden="true"></i>
                      {{ 'Link Your /social/ Account' | translate:{social: 'Google'} }}
                    </button>
                    <ng-template #unlinkGPBtn>
                        <button mat-raised-button type="button" class="social-link google-plus" (click)="unlinkSocial('google')">
                          <i class="fa fa-google" aria-hidden="true"></i>
                          {{ 'Unlink Your /social/ Account' | translate:{social: 'Google'} }}
                        </button>
                      </ng-template>
                </div>
                -->
              </div>
              <mat-form-field class="social-input">
                <input matInput type="text" id="facebook" placeholder="{{ 'Facebook Profile Link' | translate }}"
                      [(ngModel)]="profile.facebook">
                <span matSuffix>
                  <i class="fa fa-facebook facebook" aria-hidden="true"></i>
                </span>
              </mat-form-field>
              <mat-form-field class="social-input">
                <input matInput type="text" id="linkedin" placeholder="{{ 'LinkedIn Profile Link' | translate }}"
                      [(ngModel)]="profile.linkedin">
                <span matSuffix>
                  <i class="fa fa-linkedin linkedin" aria-hidden="true"></i>
                </span>
              </mat-form-field>
              <mat-form-field class="social-input">
                <input matInput type="text" id="twitter" placeholder="{{ 'Twitter Profile Link' | translate }}"
                      [(ngModel)]="profile.twitter">
                <span matSuffix>
                  <div class="icon-wrapper">
                    <img src="../../../assets/img/x-social-media-white-icon.png" alt="Twitter" aria-hidden="true">
                  </div>
                </span>
              </mat-form-field>
              <mat-form-field class="social-input hidden">
                <input matInput type="text" id="google-plus" placeholder="{{ 'GooglePlus' | translate }}"
                      [(ngModel)]="profile.googleplus">
                <span matSuffix>
                  <i class="fa fa-google-plus google-plus" aria-hidden="true"></i>
                </span>
              </mat-form-field>
              <div class="row form-group hidden">
                <div class="col-sm-10 offset-sm-2">
                  <div class="checkbox-div noselect">
                    <input type="checkbox" id="public_profile" [(ngModel)]="profile.isPublic">
                    <label for="public_profile">
                      <span>
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </span>
                      {{ 'Yes, please make my profile public.' | translate }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row form-group hidden">
                <label for="phone" class="col-sm-2 control-label">{{ 'Country' | translate }}:</label>
                <div class="col-sm-10">
                  <select class="form-control" [(ngModel)]="profile.country" (ngModelChange)="getCountryById($event)">
                    <option *ngFor="let countries of countries?.countries" [value]="countries.id">
                      {{ countries.name }} [{{ countries.iso }}]
                    </option>
                  </select>
                </div>
              </div>
              <div class="row form-group hidden">
                <label for="phone" class="col-sm-2 control-label">{{ 'Phone' | translate }}:</label>
                <div class="col-sm-10">
                  <div class="input-group">
                    <div class="input-group-addon" id="countryCode">{{ data?.country_code }}</div>
                    <input type="tel" class="form-control" id="phone" placeholder="{{ 'Phone' | translate }}"
                          [(ngModel)]="profile.phone">
                  </div>
                </div>
              </div>
              <div class="row form-group" id="pon">
                <div class="col d-flex">
                  <label for="pon" class="control-label">{{ 'Personal ID-number verification' | translate }}:
                    <br _ngcontent-c1="">
                    <span _ngcontent-c1="" class="dim-text font-12">
                    {{'If diplomas includes a personal identification number like social security numbers, a third party may enter the number to verify it. You can enable or disable this verification feature.' | translate}}
                    </span>
                  </label>
                  <ui-switch class="ui-switch" checkedLabel="{{ 'on' | translate }}" uncheckedLabel="off"
                            [(ngModel)]="profile.pi_validation"></ui-switch>
                </div>
              </div>
              <!-- 2FA -->
              <div class="row" id="pon" *ngIf="false">
                <div class="col d-flex">
                  <label for="pon" class="control-label">{{ 'Two Factor Authenticaton' | translate }}:
                    <br _ngcontent-c1="">
                    <span _ngcontent-c1="" class="dim-text font-12">
                    {{'place holder.' | translate}}
                    </span>
                  </label>
                  <ui-switch class="ui-switch" checkedLabel="{{ 'on' | translate }}" uncheckedLabel="{{ 'off' | translate }}" defaultBgColor="#be0600" [(ngModel)]="profile.enabled_2fa" (change)="changeTwoFactorAuthentication($event)"></ui-switch>
                </div>
              </div>
              <!-- 2FA -->
              <div class="button-action">
                <button mat-raised-button type="submit" class="submit" (click)="updateProfile()">
                  <i class="fa fa-check" aria-hidden="true"></i>
                  {{ 'Update Profile' | translate }}
                </button>
                <button class="reset" type="button" (click)="reset()">
                  {{ 'Cancel Changes' | translate }}
                </button>
              </div>
              <div class="reset-div"></div>
            </div>
          </mat-tab>
          <mat-tab label="{{ 'Alternative Logins' | translate }}">
              <app-alternative-logins-list></app-alternative-logins-list>
          </mat-tab>
          <mat-tab label="{{ 'Active Views' | translate }}">
            <app-share-view-list></app-share-view-list>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>

<app-two-factor-authentication-base
  #accessLevel
  [sameUser]="true"
  [enabled2FA]="profile.enabled_2fa"
  [has2faCode]="profile.has_2fa_code"
  [isEditProfile]="true"
  (isValid)="handleAccessLevel($event)"
></app-two-factor-authentication-base>